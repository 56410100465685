(function () {
  if (!window.toggleExpandSingle) {
    window.toggleExpandSingle = function (selector) {
      const target = document.querySelector(selector);
      if (target) {
        target.classList.toggle('expanded');
        target.classList.toggle('bg-gray-100');
      }
    };
  }
})();
